import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-action-bar-editor',
  templateUrl: './action-bar-editor.component.html',
  styleUrls: ['./action-bar-editor.component.scss']
})
export class ActionBarEditorComponent {

  @Input()
  primaryTitle : string = "";
  @Input()
  actionTitle : string = 'Next';
  @Output()
  onAction = new EventEmitter();

  constructor() { }

}
