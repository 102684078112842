import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Assessment, Exam } from 'src/generated/graphql';
import { AssessmentService } from '../assessment.service';


@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss']
})
export class AssessmentsComponent implements OnInit, OnDestroy {
  displayedColumnsDesktop = ['start', 'elapsed', 'score', 'review'];
  displayedColumnsMobile = ['start', 'elapsed', 'score'];
  assessments = new Map<String, Assessment>();
  querySubscription: any;

  
  get descendingAssessments(): Assessment[]{
    return Array.from(this.assessments.values()).sort((x, y)=> { return Number(y.id) - Number(x.id)});
  }

  constructor(private repo : AssessmentService) { 

  }

  ngOnInit() {
    this.querySubscription = this.getDashboardData(); //Should be an input to the component

  }

  ngOnDestroy() {
    this.querySubscription?.unsubscribe();
  }

  getDashboardData(): Subscription {
    return this.repo.watchAssessmentsWithExamStatus().
      subscribe( ({data, loading}) => { 
        data.getAssessmentsWithExamStatus.forEach(
          assessment => this.assessments.set(assessment.id, assessment)
        );
      });
  }

  displayRating(rating : Number) : String {
    return Number(rating).toPrecision(2).toString();
  }

  onLiked(assessment : Assessment, exam : Exam) {
    this.repo.likeExam(!exam.liked, assessment, exam);
  }

  minutesBetween(start: string, end : string) : Number {
    let endDate = new Date(end);
    let startDate = new Date(start);
    let diff = endDate.getTime() - startDate.getTime();
    return Math.round(((diff % 86400000) % 3600000) / 60000)
  }
}



