<h1 mat-dialog-title>Add your own question</h1>
<div mat-dialog-content >
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Question</mat-label>
    <input matInput #problem>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="problem.value" cdkFocusInitial>Save</button>
</div>