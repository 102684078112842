import { Assessment, Question, QuestionaireLocation, QuestionInput } from "src/generated/graphql";

export const AUTHOR_INTELLIGENCE = 'intelligence';
export const AUTHOR_GUEST = 'guest';

export class AssessmentEditable implements Assessment{
  
    static fromJSON(retrievedAssessment:any): AssessmentEditable {
      let questionsInstantiated = retrievedAssessment.questionsCreated.map((questionFlat:any)=> {
        return new SelectableSuggestedQuestion(questionFlat.problem, questionFlat.author, questionFlat.selected, questionFlat.exampleAnswer, questionFlat.category);
      });
      let instance = new AssessmentEditable(
        retrievedAssessment.id,
        retrievedAssessment.business,
        retrievedAssessment.role,
        questionsInstantiated,
        retrievedAssessment.focus,
        retrievedAssessment.questionaire
      );
  
      return instance;
    }
  
    get title() {
      return this.role + ", " + this.business;
    }
  
    get questionsForExam(): Question[]{
      return this.questionsCreated.filter( (item) => {
        return !!item.selected;
      }) as Question[];
    }
    
    constructor(
      public id=Date.now().toString(),
      public business : string,
      public role : string,
      public questionsCreated: Array<SelectableSuggestedQuestion>,
      public focus : string,
      public questionaire?: QuestionaireLocation
      ) {
    }
  
    addQuestion(question: SelectableSuggestedQuestion) {
      this.questionsCreated.push(question);
    }

    removeUnselectedQuestions() {
			this.questionsCreated = this.questionsCreated.filter( question => question.selected);
    }
  }

	export class SelectableSuggestedQuestion implements QuestionInput{
		get isUserQuestion(): boolean {
			return !this.isIntelligenceQuestion;
		}
	
		get isIntelligenceQuestion(): boolean {
			return this.author == AUTHOR_INTELLIGENCE
		}
	
		constructor(public problem: string, public author: string, public selected=false, public exampleAnswer?: string | null, public category?: string | null){
			//Defaulting to null, to give it a value. Else the client side cache will barf when storing the question.
      if(!category){
        this.category=null;
      }
      if(!exampleAnswer){
        this.exampleAnswer=null; 
      }
		}
	}