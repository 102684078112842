import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { AssessmentEditable, SelectableSuggestedQuestion } from '../assessment-editable';
import { EditRecommendationDialogComponent } from '../edit-recommendation-dialog/edit-recommendation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { QuestionInput } from 'src/generated/graphql';


@Component({
  selector: 'app-reorder-list',
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss']
})
export class ReorderListComponent implements OnChanges {
  @Input("assessment")
  assessment? : AssessmentEditable;
  selectedItemsList? : Array<SelectableSuggestedQuestion>;
  private originalToSelectedMapping? : number[];


  buildMapping (){
    this.originalToSelectedMapping = new Array<number>();
    this.assessment?.questionsCreated.forEach((item, index)=> {
      if(!!item.selected){
        this.originalToSelectedMapping?.push(index);
        return true;
      }
      return false;
    })
  }

  constructor( public materialDialog:MatDialog) { 
    
  }
  // Take inspiration from: https://v13.material.angular.io/cdk/drag-drop/overview#cdk-drag-drop-sorting 

  ngOnChanges(changes: SimpleChanges): void {
    this.buildMapping();
  }

  reordered(event: CdkDragDrop<string[]>){
    //The move comes from the selectedItemsList
    let mappedPrevIndex = this.originalToSelectedMapping![event.previousIndex];
    let mappedCurrIndex = this.originalToSelectedMapping![event.currentIndex];

    moveItemInArray(this.assessment!.questionsCreated, mappedPrevIndex, mappedCurrIndex);
    this.buildMapping();
  }

  openEditAnswerDialog(question: String, exampleAnswer: String) { // https://v13.material.angular.io/components/dialog/examples#dialog-overview
    const dialogRef = this.materialDialog.open(EditRecommendationDialogComponent, {
      maxWidth: '800px',
      minWidth: '400px',
      data: {question: question, exampleAnswer: exampleAnswer}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){//If the dialogue was closed in the affirmative
        let itemToUpdate = this.assessment?.questionsCreated.find( item => {
          return item.problem == result.problem;
        }) as QuestionInput;
        if(itemToUpdate){
          itemToUpdate.exampleAnswer = result.exampleAnswer;
        }
      }
    });
  }
}
