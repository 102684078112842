
<mat-toolbar color="primary">
  <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button> -->
  <span>T</span><span fxHide.lt-sm>alent Test</span>
  <span class="example-spacer"></span>

  <!-- For Managers -->
  <div *ngIf="isAuthenticated">
    <a mat-button routerLink="/dashboard">Collection</a>
    <a mat-button href="/edit">New</a> <!-- href triggers application reload -->
    <a mat-button href="mailto:help@talenttest.ai?subject=Quick%20question" title="help@talenttest.ai">
        <!-- <mat-icon>live_help</mat-icon> -->
        Help
    </a>
    <button mat-icon-button aria-label="Logout" (click)="logout()" title="Logout">
      <mat-icon>logout</mat-icon>
    </button>
  </div>

  <!-- For Guests -->
  <div *ngIf="!isAuthenticated">
    <a mat-button href="/edit">New</a> <!-- href triggers application reload -->
    <button mat-icon-button (click)="login()" aria-label="Login" title="Login">
      <mat-icon>login</mat-icon>
    </button>
  </div>



</mat-toolbar>
