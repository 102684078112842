import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})

// One week membership on talenttest
// TEST: Trigger with: https://demo.talenttest.ai/join?period=price_1Mj0EgCwzuQwL2OSaG43xw7t
export class JoinComponent {
  private priceId$ = new EventEmitter<string>();
  private clientReferenceId$ = new EventEmitter<string>();



  /*
    Either purchase new membership, or if the user has not selected one, redirect back to the landing page for selection.
  */
  constructor(private authService: AuthService, private activatedRoute : ActivatedRoute) {

    //When a user profile is available, extract the userId
    authService.user$.subscribe( user => {
      const priceId = this.activatedRoute.snapshot.queryParams["period"];
      const clientReferenceId = user?.sub;
      const email = user?.email;

      let prepareCheckoutUrl = environment.gateway.prepareCheckoutUrl
        .replace('{clientReferenceId}', encodeURIComponent(clientReferenceId!))
        .replace('{priceId}', priceId)
        .replace('{email}', encodeURIComponent(email!));
      window.location.assign(prepareCheckoutUrl);

    });

  }

}
