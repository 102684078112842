import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface EditRecommendationDialogResults {
  question: String;
  exampleAnswer: String;
}

@Component({
  selector: 'app-edit-recommendation-dialog',
  templateUrl: './edit-recommendation-dialog.component.html',
  styleUrls: ['./edit-recommendation-dialog.component.scss']
})
export class EditRecommendationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<EditRecommendationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditRecommendationDialogResults,
  ) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
