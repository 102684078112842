import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// GraphQL is setting x-api-key by default, so we need to strip it out, when Auth0 is providing access tokens.
@Injectable()
export class RemoveAPIKeyInterceptor implements HttpInterceptor {
  intercept(originalRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(originalRequest.headers.has('Authorization')){
      let updatedRequest = originalRequest.clone({
        headers: originalRequest.headers.delete('x-api-key')
      });
      return next.handle(updatedRequest);
    } else {
      return next.handle(originalRequest);
    } 
  }
}

@NgModule({ //TODO intercept on errors
  imports: [
    CommonModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      audience: environment.auth0.audience, //Required for AppSync to accept the token
      redirectUri: window.location.origin,
      httpInterceptor: {
        // Configuration examples
        // https://github.com/auth0/auth0-angular/blob/d9ba5bd6991bf87ad76db6122c9d5f01ad87dbbf/projects/auth0-angular/src/lib/auth.interceptor.spec.ts#L64
        allowedList: [
          { // GraphQL Endpoints should be augmented with tokens, if the person is logged in, otherwise passthrough
            uri: environment.appsync.url,
            allowAnonymous: true, //Should pass through calls where the API key can be used.
          }
        ]
      },
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RemoveAPIKeyInterceptor, multi: true },
  ],
  exports: []
})
export class AuthRoutingModule { }
