<a mat-button class="back" routerLink="../../../" color="primary">
    <mat-icon>arrow_back</mat-icon>Back
</a>

<div class="headline for-print" fxLayout="row">
    <div fxFlex>
        <h1>{{assessment?.title}}</h1>
        <h3>{{exam?.startDate | date:'short'}}</h3>
    </div>
    <div fxFlex="nogrow">
        <app-star-rating [rating]="(exam?.aggregatedRating) || 0"></app-star-rating>
    </div>
</div>

    <!-- 
        Insert explaination of results
        - The candidate is ranked x out of y, 
        - The score is calculated based on xyz
    -->

<ul class="answered-questions for-print">
    <li *ngFor="let answer of exam?.answers">
        <mat-card *ngIf="answer">
            <div fxLayout="row" fxLayoutGap="5%">
                <div fxFlex>
                    <mat-card-title>
                        <span>{{answer.problem}}</span>
                    </mat-card-title>
                </div>
                <div fxFlex="nogrow">
                    <app-star-rating [rating]="(answer.ratingScore) || 0"></app-star-rating>
                </div>
            </div>


            <mat-card-content>
                <div class="improvement" *ngIf="showImprovement(answer)">
                    <mat-icon>info</mat-icon>
                    {{answer.advice}}
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                    <div fxFlex>
                        <span>Recommended answer</span>
                        <p>
                            {{getExampleAnswerForProblem(answer.problem)}}
                        </p>
                    </div>
                    <div fxFlex="40%">
                        <span>My answer</span>
                        <p>
                            {{answer.solution}}
                        </p>
                        
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </li>
</ul>  