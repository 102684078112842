import { Component,  EventEmitter,  Input,  OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { merge, switchMap, filter, tap, Observable, combineLatest, map } from 'rxjs';
import { QueryGenerateFocusForArgs } from 'src/generated/graphql';
import { Position } from '../intelligence-dto';

@Component({
  selector: 'app-business-and-role',
  templateUrl: './business-and-role.component.html',
  styleUrls: ['./business-and-role.component.scss']
})
export class BusinessAndRoleComponent implements OnChanges, OnInit{


  @Input()
  business?: string;
  @Input()
  role?: string;
  @Input()
  focus?: string;

  private buinessControl = new FormControl('');
  private roleControl = new FormControl('');
  private focusControl = new FormControl('');

  businessAndRole = new FormGroup({
    business: this.buinessControl,
    role: this.roleControl,
    focus: this.focusControl
  });

  @Output() newPositionEvent : Observable<Position> = this.businessAndRole.valueChanges;
  
  canEditFocus() : boolean {
    return !!this.roleControl.value && !!this.buinessControl.value
     && !this.focusControl.value; //If focus is already filled, then dont generate more
  }

  public isLoadingFocus = false;
  public startFocusGeneration = new EventEmitter<void>();
  
  constructor(private apollo: Apollo) { }



  ngOnInit(): void {
    combineLatest([this.startFocusGeneration, merge(this.roleControl.valueChanges, this.buinessControl.valueChanges)])
    .pipe(
      map( ([clickEvent, position]) => position)
      , filter(() => 
        {
          //Only process the form when both fields are filled
          return this.canEditFocus();
        }
      )
    )
    .pipe(
      tap( _ => this.isLoadingFocus = true),
      switchMap( (changedPosition : Position) => {
        
        return this.apollo.watchQuery({ //watchQuery can get unsubscribed with switchMap
                  query: FOCUS_QUERY,
                  variables: {
                    role: this.roleControl.value,
                    business: this.buinessControl.value
                  },
                  fetchPolicy: 'network-only'
                }).valueChanges;
      }),
      tap( _ => this.isLoadingFocus = false)
    )
    .subscribe(({data, loading} : ApolloQueryResult<{generateFocusFor: string[]}>) => {
      this.focusControl.setValue(data.generateFocusFor, {emitEvent: true}); 
    });

  }

  /* 
    In-determinate timing on this trigger
  
    Set-up the initial values, when loading an old assessment
    Using the onChanges lifcycle hook, which listens to changes to the @Input bound variables,
    but they will be set by the parent component after init, and after the routing service responds with query params.
  */
  ngOnChanges(changes: SimpleChanges): void {

    let controlsEmpty = !this.buinessControl.value && !this.roleControl.value;
    let inputProvided = !!this.business && !!this.role; //Focus is optional
    if(controlsEmpty && inputProvided){ 
      if(this.focus){
        this.focusControl.enable({emitEvent: false});
      }
      
      this.buinessControl.setValue(this.business, {emitEvent: false});
      this.roleControl.setValue(this.role, {emitEvent: false});
      this.focusControl.setValue(this.focus, {emitEvent: false});

    }
  }

}

const FOCUS_QUERY = gql<{generateFocusFor: string[]}, QueryGenerateFocusForArgs>`
  query loadNewEditorFocus($role: String!, $business: String!) {
    generateFocusFor(role: $role, business: $business)
  }`;