import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Answer, Assessment, Exam } from 'src/generated/graphql';
import { AssessmentService } from '../assessment.service';

@Component({
  selector: 'app-exam-summary',
  templateUrl: './exam-summary.component.html',
  styleUrls: ['./exam-summary.component.scss']
})
export class ExamSummaryComponent implements OnInit {
  exam? : Exam
  assessment? : Assessment;

  constructor(public assessmentService : AssessmentService, public activatedRoute : ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let assessmentId = params['assessmentId'];
      let examId = params['examId'];

      if(assessmentId && examId){
        this.assessmentService.retrieveAssessmentWithExamsSummary(assessmentId)
          .then((retrievedAssessment : Assessment)=>{
            this.assessment = retrievedAssessment;
            let examInFocus = retrievedAssessment.exams?.find(exam => exam?.id == examId);
            if(examInFocus){
              this.exam = examInFocus;
            }
          }).catch(err => {
            console.log(err);
          });
      }
      
    });
  }

  getExampleAnswerForProblem(problem: string){
    return this.assessment?.questionsForExam?.find(question => question?.problem == problem)?.exampleAnswer;
  }

  showImprovement(answer: Answer){
    let hasAdvice = !!answer.advice;
    let hasLowScore = !answer.ratingScore || answer.ratingScore < 8;
    return hasAdvice && hasLowScore;
  }

}
