<h1 mat-dialog-title>Edit</h1>
<div mat-dialog-content >
  <p>
    {{data.question}}
  </p>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Recommended answer</mat-label>
    <textarea matInput [value]="data.exampleAnswer" #exampleAnswerUpdate
    cdkTextareaAutosize
    cdkAutosizeMinRows="3"
    cdkAutosizeMaxRows="9"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="{exampleAnswer: exampleAnswerUpdate.value, problem: data.question}" cdkFocusInitial>Save</button>
</div>