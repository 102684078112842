import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Apollo, gql } from 'apollo-angular';
import pick from 'lodash/pick';
import { firstValueFrom } from 'rxjs';
import { Assessment, Exam, JustId, MutationGenerateQuestionaireArgs, MutationLikeExamArgs, MutationUpdateQuestionaireArgs, QueryGenerateExampleAnswersForArgs, Question, QuestionaireLocation, QuestionExampleAnswer, QuestionInput } from 'src/generated/graphql';
import { AssessmentEditable } from './assessment-editable';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {
  retrieveExam(assessmentId: any, examId: any) {
    throw new Error('Method not implemented.');
  }

  constructor(private apollo: Apollo, private auth0 : AuthService) { }
  
  public storeDraft(assessment : AssessmentEditable) {

      //Write to the cache, When a single assessment is queried, then return this one from cache
      (assessment as Assessment).__typename = "Assessment"; //Make sure the typename is set before writing to the cache
      assessment.questionaire = {id: null, url: null}; //This is necessary to ensure the object gets stored and can be retrieved from apollo-cache without errors.

      this.apollo.client.writeQuery({
        query: SINGLE_ASSESSMENT,
        data: {
          getAssessment: assessment
        },
        variables: {assessmentId: assessment.id}
      });
  }
  
  public async storePermanent(assessment: AssessmentEditable) : Promise<AssessmentEditable>{
    let assessmentVariables = pick(assessment, 
      [
        'id','title', 'business', 'role', 'focus', 'questionsForExam', 'questionsCreated',
      ]) as Assessment;
    assessmentVariables.questionaire = pick(assessment.questionaire, ['id', 'url']); //Make sure only fields for the input type are used

    let storeAssessmentMutation = this.apollo.mutate({
        mutation: CREATE_ASSESSMENT,
        variables: assessmentVariables,
        update: (cache, creationResult) => {
          const cacheState = cache.readQuery({query: DASHBOARD_QUERY});
          
          let newResult = cacheState?.getAssessmentsWithExamStatus.concat(creationResult.data!.createAssessment) || [creationResult.data!.createAssessment]; // If there is nothing cached already, then add the result as the first item
          cache.writeQuery({query: DASHBOARD_QUERY, data: {getAssessmentsWithExamStatus: newResult}});
        }
      });

    return firstValueFrom(storeAssessmentMutation).then((response)=> {
      //Successfully stored assessment
      if(assessment.questionaire?.id){ //Did it already have a questionaire?
        this.updateQuestionaire(assessment);
      }
      return assessment;
    });
  }

  async retrieve(id: string) : Promise<{assessment: AssessmentEditable, hydrationDone: Promise<null>}> {
      //Hit the Apollo Angular cache, and let it resolve locally if possible
      return this.retrieveRemotely(id);
  }

  private retrieveRemotely(id: string): Promise<{assessment: AssessmentEditable, hydrationDone: Promise<null>}> {
    return firstValueFrom(
      this.apollo.watchQuery({
        query: SINGLE_ASSESSMENT,
        variables: {assessmentId: id},
        fetchPolicy: 'cache-and-network', // This ensures that guests can pre-load an edited assessment into the cachce, before it can exist remotely.
      }).valueChanges)
    .then((cacheResponse) => { 
      // https://www.apollographql.com/docs/react/caching/cache-interaction/#readquery
      // Apollo: writeToStore.js:157 !policies.getReadFunction
      // If the cache is missing data for any of the query's fields, readQuery returns null. It does not attempt to fetch data from your GraphQL server.

      let assessment = AssessmentEditable.fromJSON(cacheResponse.data.getAssessment);
      
      //Async hydration
      ;

      return {
        assessment: assessment, 
        hydrationDone: this.hydrateAssessment(assessment)
      };
    })
  }

  private hydrateAssessment(assessment: AssessmentEditable): Promise<any> {
    let promises = new Array<Promise<any>>();

    if(!assessment.questionaire?.id){ //Async, get a preview link for the form
      let questionaireIsHydrated = this.generateQuestionaire(assessment).then(updatedAssessment => {
        assessment.questionaire = updatedAssessment.questionaire;
      });

      promises.push(questionaireIsHydrated);
    }

    let questionsMissingExampleAnswer = assessment.questionsForExam.filter(question => !question.exampleAnswer);
    if(questionsMissingExampleAnswer.length > 0){
      let exampleAnswerIsHydrated = this.generateExampleAnswers(assessment.business, assessment.role, questionsMissingExampleAnswer).then((exampleAnswers : Map<string, string>) => {
        assessment.questionsForExam.forEach(question => {
          if(!question.exampleAnswer){
            question.exampleAnswer = exampleAnswers.get(question.problem);
          }   
        });
        return assessment;
      });

      promises.push(exampleAnswerIsHydrated);
    }

    return Promise.all(promises);
  }

  generateExampleAnswers(business: string, role: string, questions : Question[]) : Promise<Map<string, string>> {
    return firstValueFrom(
      this.apollo.watchQuery({
        query: EXAMPLE_ANSWERS,
        variables: {
          problems: questions.map(question => question.problem),
          business: business,
          role: role
        }
      }).valueChanges).then(result => {
        let examples = new Map<string, string>();
        result.data.generateExampleAnswersFor.forEach(question => {
          examples.set(question.problem, question.exampleAnswer);
        });

        return examples;
      });
  }

  public retrieveAssessmentWithExamsSummary(assessmentId: string): Promise<Assessment> {
    return firstValueFrom(
      this.apollo.watchQuery({
        query: SINGLE_ASSESSMENT_WITH_EXAMS,
        variables: {assessmentId: assessmentId},
        fetchPolicy: 'cache-and-network', // We dont expect to actually hit the network, unless the manager is using a direct link
      }).valueChanges)
    .then((cacheResponse) => { 
      return cacheResponse.data.getAssessment;
    });
  }

  private async generateQuestionaire(assessment: AssessmentEditable) : Promise<AssessmentEditable>{
    let variables = {
      assessmentId: assessment.id,
      questionsForExam: assessment.questionsForExam.map(question => question.problem),
      title: assessment.title,
      business: assessment.business,
      role: assessment.role
    };

    let mutation = this.apollo.mutate({
        mutation: GENERATE_QUESTIONAIRE,
        variables: variables
      });
    
    return firstValueFrom(mutation).then((response)=> {
      assessment.questionaire = response.data!.generateQuestionaire;
      return assessment;
    });
  }

  /*
    Does not change the ID or location of the questionaire form.
  */
  private async updateQuestionaire(assessment: AssessmentEditable) : Promise<AssessmentEditable>{
    if(!assessment.questionaire?.id){
      throw 'Can only update on persisted questionaires';
    }
    
    let variables = {
      assessmentId: assessment.id,
      questionsForExam: assessment.questionsForExam.map(question => question.problem),
      title: assessment.title,
      questionaireId: assessment.questionaire!.id
    };

    let mutation = this.apollo.mutate({
        mutation: UPDATE_QUESTIONAIRE,
        variables: variables
      });
    
    return firstValueFrom(mutation).then(_ => assessment);
  }

  public watchAssessmentsWithExamStatus(){
    return this.apollo.watchQuery({
      query: DASHBOARD_QUERY,
      fetchPolicy: 'cache-and-network', //https://www.apollographql.com/docs/react/data/queries/#cache-and-network,
      pollInterval: 15000 // 5 secs
    }).valueChanges;
  }

  public likeExam(likeStatus: boolean, assessment : Assessment, exam : Exam) { //This changes the value of the watchAssessmentsWithExamStatus query
    let variables = {
      assessmentId: assessment.id,
      examId: exam.id,
      liked: likeStatus
    };

    let mutation = this.apollo.mutate({
        mutation: LIKE_EXAM,
        variables: variables
      });

    return firstValueFrom(mutation).then(_ => assessment);
  }

}

const DASHBOARD_QUERY = gql<{getAssessmentsWithExamStatus: Assessment[]}, {}>`
  query loadDashboard {
    getAssessmentsWithExamStatus {
      id,
      title,
      business,
      role,
      questionaire {
        id
        url
      },
      exams {
        id
        candidateName
        candidateEmail
        startDate
        endDate
        aggregatedRating
        liked
      }
    }
  }`

interface AssessmentCreation { //Onlt necessary because subqueries in responses are named
  createAssessment: Assessment
}
const CREATE_ASSESSMENT = gql<AssessmentCreation, Assessment>`
  mutation createAssessmentMutation($id: String!, $role: String!, $business: String!, $title: String!, $focus: String, $questionsForExam: [QuestionInput!]!, $questionsCreated: [QuestionInput!]!, $questionaire: QuestionaireLocationInput){
    createAssessment(
      id: $id,
      title: $title,
      focus: $focus,
      business: $business,
      role: $role,
      questionsForExam: $questionsForExam,
      questionsCreated: $questionsCreated,
      questionaire: $questionaire
    ) {
      id,
      business,
      exams {
        id,
        candidateName,
        candidateEmail,
        startDate,
        endDate,
        aggregatedRating
      },
      focus,
      questionaire {
        id
        url
      },
      questionsForExam {
        problem, selected, author, exampleAnswer
      },
      questionsCreated {
        problem, selected, author, exampleAnswer
      },
      role,
      title
    }
  }`;

// questionaire here should be optimal, to both support storing local guest versions, and retrieving logged-in versions 
// queationsForExam.exampleAnswer should not be included, otherwise the cache fails from Edit to Settings page 
const SINGLE_ASSESSMENT = gql<{getAssessment: Assessment},{assessmentId: string}>`
query getAssessment($assessmentId: ID!){
    getAssessment(
      assessmentId: $assessmentId,
    )  {
      id,
      business,
      focus,
      questionaire{
        id
        url
      },
      questionsForExam {
        problem, selected, author, category, exampleAnswer
      },
      questionsCreated {
        problem, selected, author, category, exampleAnswer
      },
      role,
      title
    }
}`;

const SINGLE_ASSESSMENT_WITH_EXAMS = gql<{getAssessment: Assessment},{assessmentId: string}>`
query getAssessment($assessmentId: ID!){
    getAssessment(
      assessmentId: $assessmentId,
    )  {
      id,
      business,
      focus,
      role,
      title,
      questionsForExam {
        problem
        exampleAnswer
      }
      exams {
        id,
        candidateName,
        candidateEmail,
        startDate,
        endDate,
        aggregatedRating,
        answers {
          id,
          problem,
          solution,
          advice,
          ratingScore,
          ratingSummary
        }
      },
    }
}`;

const GENERATE_QUESTIONAIRE = gql<{generateQuestionaire: QuestionaireLocation}, MutationGenerateQuestionaireArgs>`
  mutation generateQuestionaire($assessmentId: ID!, $title: String!, $business: String!, $role: String!, $questionsForExam: [String]!){
    generateQuestionaire(
      title: $title,
      business: $business,
      role: $role,
      assessmentId: $assessmentId,
      questionsForExam: $questionsForExam
    ) {
      id
      url
    }
  }`;

const UPDATE_QUESTIONAIRE = gql<{updateQuestionaire: JustId}, MutationUpdateQuestionaireArgs>`
mutation updateQuestionaire($assessmentId: ID!, $title: String!, $questionsForExam: [String]!, $questionaireId: ID!){
  updateQuestionaire(
    title: $title,
    assessmentId: $assessmentId,
    questionsForExam: $questionsForExam,
    questionaireId: $questionaireId
  ) {
    id
  }
}`;

//Only retrieving enough variables in the response to avoid the Apollo Cache starts puking
const LIKE_EXAM = gql<{likeExam: Exam}, MutationLikeExamArgs>`
mutation likeExam($assessmentId: ID!, $examId: ID!, $liked: Boolean!) {
  likeExam(assessmentId: $assessmentId, examId: $examId, liked: $liked) {
    liked
    id
  }
}
`;

const EXAMPLE_ANSWERS = gql<{generateExampleAnswersFor: Array<QuestionExampleAnswer>}, QueryGenerateExampleAnswersForArgs>`
query generateExampleAnswersFor($problems: [String!]!, $business: String!, $role: String!){
  generateExampleAnswersFor(
      problems: $problems,
      business: $business,
      role: $role,
    )  {
      problem,
      exampleAnswer
    }
}`;