import {BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import { RetryLink } from "@apollo/client/link/retry";
import {from, InMemoryCache} from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';



export function createApollo(httpLink: HttpLink): {} {
  const cache = new InMemoryCache();
  persistCache({cache,
    debounce: 0, //TWA-68 should optimise this. Debounce will not work as it is the luck of the draw, when leaving the application from the editor to authorize as a member using auth0
    storage: new LocalStorageWrapper(window.localStorage)}
  ); //May need to use await here, and use Angular sync factory during DI: https://angular.io/api/core/APP_INITIALIZER
  
  const linkOverHttp = httpLink.create({ uri: environment.appsync.url,
    headers: new HttpHeaders({'x-api-key':  environment.appsync.key}) //API key is default, when a user is not logged in
  });

  const retryOverHttp = new RetryLink(); //Default configuration: https://www.apollographql.com/docs/react/api/link/apollo-link-retry#default-configuration
  return {
    link: from([retryOverHttp, linkOverHttp]),
    cache: cache,
  };
}

@NgModule({
  imports: [BrowserModule, ApolloModule],
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
// Switch to using handwritten queries and responses instead of autogenerated operations
// https://apollo-angular.com/docs/development-and-testing/using-typescript#options