<div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between" fxLayout.lt-md="column">
    <div fxFlex.gt-sm="40%">
        <h1>Thank you for joining us!</h1>
    
        <h2>Create your first test now</h2>
        <button mat-raised-button color="primary" [routerLink]="['../edit']">Start</button>
    </div>
    <div fxFlex.gt-sm="60%">
        <img style="display: block; width: 100%; height: auto;" src="/assets/trolley-start.png" alt="Women riding in a shopping trolley, ready for adventure">
    </div>
</div>
