
<h1>New practice interview</h1>


<mat-stepper orientation="vertical" [linear]="true" #stepper [selectedIndex]="!!presentedQuestions.length ? 1 : 0">
    <mat-step [editable]="!presentedQuestions.length" >
        <ng-template matStepLabel>The role</ng-template>

        <app-business-and-role fxFlex="100"
        [business]="assessmentDraft?.business" [role]="assessmentDraft?.role" [focus]="assessmentDraft?.focus"></app-business-and-role>

        <div fxLayout="row" fxLayoutAlign="start" style="padding-top: 32px;">
            <button [disabled]="!canRequestQuestions" mat-button (click)="clickMoreQuestions()" matStepperNext>
                Next
            </button>
        </div>

    </mat-step>

    <mat-step [completed]="(!!isLoadingQuestions || !!presentedQuestions.length)" #popularquestions>
        <ng-template matStepLabel>Popular questions</ng-template>

        
        <div *ngIf="!!isLoadingQuestions">
            <p>We tailor the questions while you wait</p>
            <mat-progress-bar style="margin-top: 8px; margin-bottom: 8px;"  mode="indeterminate"></mat-progress-bar>
            <h1>Did you know?</h1>
            <h3>On average it takes 5 weeks before the first interview and the job offer.</h3>
            
        </div>

        <ul class="proposed-questions" >
            <li *ngFor="let suggestion of presentedQuestions">
                
                <mat-card>
                    <div fxLayout="row">
                        <div class="left-actions">
                            <button mat-icon-button (click)="suggestion.selected = !suggestion.selected">
                                <mat-icon inline="true" *ngIf="!suggestion.selected ">radio_button_unchecked</mat-icon>
                                <mat-icon inline="true" *ngIf="suggestion.selected">check_circle_outline</mat-icon>
                            </button>
                        </div>
                        <div class="right-content">
                            <mat-card-title>
                                <!-- Question itself -->
                                {{suggestion.problem}}
                            </mat-card-title>
                        </div>
                    </div>
                    
                </mat-card>
            </li>
        </ul>

        <div *ngIf="!isLoadingQuestions">
            <button mat-button matStepperNext>Next</button>
        </div>

        <div *ngIf="isLoadingQuestions">
            <p>We are retrieving additional in-depth questions ...</p>
        </div>

    </mat-step>
    <mat-step *ngIf="questionCategories.length > 1" [editable]="!!questionCategories">
        <ng-template matStepLabel>In-depth questions</ng-template>

        <mat-accordion *ngFor="let category of questionCategories">
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between">
                <mat-panel-title fxFlex="80">{{category}}</mat-panel-title>
                <mat-panel-description fxFlex>{{questionsFrom(category).length}}</mat-panel-description>
              </mat-expansion-panel-header>
              <!-- Selectable questions -->

              <ul class="proposed-questions" >
                <li *ngFor="let suggestion of questionsFrom(category)">
                    
                    <mat-card>
                        <div fxLayout="row">
                            <div class="left-actions">
                                <button mat-icon-button (click)="suggestion.selected = !suggestion.selected">
                                    <mat-icon inline="true" *ngIf="!suggestion.selected ">radio_button_unchecked</mat-icon>
                                    <mat-icon inline="true" *ngIf="suggestion.selected">check_circle_outline</mat-icon>
                                </button>
                            </div>
                            <div class="right-content">
                                <mat-card-title>
                                    <!-- Question itself -->
                                    {{suggestion.problem}}
                                </mat-card-title>
                            </div>
                        </div>
                        
                    </mat-card>
                </li>
              </ul>

            </mat-expansion-panel>
        </mat-accordion>

        <div fxLayout="row" fxLayoutAlign="start" style="padding-top: 32px;">
            <button mat-button (click)="nextStep()">Continue to arrange questions</button>
        </div>

    </mat-step>
    <mat-step [completed]="false">
        <!-- This steo should never be shown-->
        <ng-template matStepLabel>Answers</ng-template>
        <button mat-button (click)="nextStep()">Continue to arrange quetions</button>
    </mat-step>
</mat-stepper>

<!-- Categories for loading more questions go here -->


<!-- These features are not used, so commenting them out for now
    
    <div *ngIf="(presentedQuestions!.length > 0)" fxLayout="column" fxLayoutAlign="space-around center" class="suggested-actions">

    <button mat-flat-button color="primary" (click)="openAddQuestionDialog()">
        <span>Add your own question</span>
    </button>

    <button mat-stroked-button (click)="clickMoreQuestions()">
        <span>More questions</span>
    </button>

    </div> 
-->
