// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appsync: {
    url: "https://5gz45g32effpne35zp72jkieoq.appsync-api.eu-north-1.amazonaws.com/graphql",
    key: "da2-mz3urqi6zbbujbboofmwjuqhbe"
  },
  auth0: {
    domain: 'develop-talenttest-ai.eu.auth0.com',
    clientId: 'G1KjHnUyIc0FM2MDrAKnw8iDhd3XfL9Z',
    audience: 'https://aws.amazon.com', //Required for AppSync to accept the token
  },
  gateway: { //This should be moved over to appSync and require OIDC tokens to be called, for simplicity and reduced attack vector
    prepareCheckoutUrl: 'https://develop.talenttest.ai/webhook/stripe/checkout/{priceId}/prepare/for/{clientReferenceId}/at/{email}'
  },
  landingPage: {
    membership: 'https://www.talenttest.ai/your-membership-has-ended'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
