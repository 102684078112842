import { Pipe, PipeTransform } from '@angular/core';
import { format, TDate } from 'timeago.js';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: TDate | undefined , ...args: unknown[]): unknown {
    return format(value || "");
  }

}
