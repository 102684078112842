<!-- Convert 10 point rating to 5 star indication -->
<mat-icon class="empty star" *ngIf="(rating/2) < 0.5">star_border</mat-icon>
<mat-icon class="filled star" *ngIf="(rating/2) >= 0.5">star_rate</mat-icon>

<mat-icon class="empty star" *ngIf="(rating/2) < 1.5">star_border</mat-icon>
<mat-icon class="filled star" *ngIf="(rating/2) >= 1.5">star_rate</mat-icon>

<mat-icon class="empty star" *ngIf="(rating/2) < 2.5">star_border</mat-icon>
<mat-icon class="filled star" *ngIf="(rating/2) >= 2.5">star_rate</mat-icon>

<mat-icon class="empty star" *ngIf="(rating/2) < 3.5">star_border</mat-icon>
<mat-icon class="filled star" *ngIf="(rating/2) >= 3.5">star_rate</mat-icon>

<mat-icon class="empty star" *ngIf="(rating/2) < 4.5">star_border</mat-icon>
<mat-icon class="filled star" *ngIf="(rating/2) >= 4.5">star_rate</mat-icon>
