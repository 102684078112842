import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentEditable } from '../assessment-editable';
import { AssessmentService } from '../assessment.service';
@Component({
  selector: 'app-assessment-settings',
  templateUrl: './assessment-settings.component.html',
  styleUrls: ['./assessment-settings.component.scss']
})

// TODO Remove this page entirely
export class AssessmentSettingsComponent implements OnInit {
  assessmentDraft? : AssessmentEditable;
  constructor(public assessmentService : AssessmentService, public activatedRoute : ActivatedRoute, private router : Router) {

  }

  resolveFullyLoaded?: (value: unknown) => void;
  public fullyLoaded = new Promise((resolve) =>{
    this.resolveFullyLoaded = resolve;
  })

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      let id = params['assessmentId'];
      if(id){
        this.assessmentService.retrieve(id)
          .then((result)=>{
            result.hydrationDone.then(this.resolveFullyLoaded);

            this.assessmentDraft = result.assessment;
            return this.assessmentDraft;

          }).catch(err => {
            console.log(err);
          });
      }
      
    });
  }

  editAssessment(){
    this.storeHydratedDraft().then(  //Store the draft between routes
      (assessment) => {
        this.router.navigate(['edit'], {queryParams: {assessmentId: assessment.id}})
      }
    )
  }

  nextStep(){
    this.storeHydratedDraft().then(
      (assessment) => { //Wait for the Assessment to be stored, before entering the dashboard where all will be reloaded
        this.router.navigate(['dashboard']);
      });
  }

  storeHydratedDraft() : Promise<AssessmentEditable> {
    return this.fullyLoaded.then(_ => {
      return this.assessmentService.storePermanent(this.assessmentDraft!);
    })
  }

  printInterviewGuide() : void {
    window.print();
  }

}
