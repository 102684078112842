import { Component, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  get isAuthenticated() {
    return localStorage.getItem('isAuthenticated') == 'true' ? true : false;
  }
  
  constructor(public auth: AuthService, @Inject(DOCUMENT) private doc: Document, private apollo : Apollo) {
    auth.isAuthenticated$.subscribe(isAuthenticated => {
      this.setAuthenticationLocally(isAuthenticated);
    })
  }

  private setAuthenticationLocally(isAuthenticated : boolean){
    localStorage.setItem('isAuthenticated', isAuthenticated ? 'true' : 'false');
  }

  login(): void {
    this.auth.loginWithRedirect({
    });
  }

  logout(): void {
    this.apollo.client.clearStore();
    this.auth.logout({ returnTo: this.doc.location.origin });
  }
}
