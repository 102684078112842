<div class="guide for-print">
    <h2>Practice interview</h2>
    <h1>{{assessmentDraft?.title}}</h1>
</div>
<div class="content for-print">
    <app-reorder-list [assessment]="assessmentDraft"></app-reorder-list>

    <div fxLayout="row" fxLayoutAlign="space-between">

        <div>
            <button class="not-for-print" mat-button (click)="nextStep()">
                <mat-icon>save</mat-icon><span>Save and continue</span>
            </button>
        </div>

        <div>
            <button class="not-for-print" mat-button (click)="editAssessment()">
                <mat-icon>format_list_bulleted_add</mat-icon><span>Add more questions</span>
            </button>
        
            <button class="not-for-print" mat-button (click)="printInterviewGuide()">
                <mat-icon>print</mat-icon><span>Print</span>
            </button>
        </div>

    </div>

</div>
