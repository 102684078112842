import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AssessmentEditorComponent } from '../assessment-editor/assessment-editor.component';
import { AssessmentSettingsComponent } from '../assessment-settings/assessment-settings.component';
import { AssessmentsComponent } from '../assessments/assessments.component';
import { BrokenLinkComponent } from '../broken-link/broken-link.component';
import { ExamSummaryComponent } from '../exam-summary/exam-summary.component';
import { JoinComponent } from '../join/join.component';
import { ThankYouComponent } from '../thank-you/thank-you.component';

// Custom type, to replicate the normal AuthGuard from Auth0, but enforce a sign-up login flow for users.
@Injectable({
  providedIn: 'root',
})
export class AuthGuardWithSignup implements CanActivate {
  constructor(private auth: AuthService) {

  }
  // https://github.com/auth0/auth0-angular/blob/fa2bf718860934febd650de72d9e16ead566f9e1/projects/auth0-angular/src/lib/auth.guard.ts#L25
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  // https://github.com/auth0/auth0-angular/blob/fa2bf718860934febd650de72d9e16ead566f9e1/projects/auth0-angular/src/lib/auth.guard.ts#L39
  private redirectIfUnauthenticated(
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      tap((loggedIn: boolean) => {
        if (!loggedIn) {
          this.auth.loginWithRedirect({
            appState: { target: state.url },
            screen_hint: 'signup' //If the user is not logged in, then the default should be a sign-up.
          });
        } else {
          //Is logged in - but does the user have active membership?
          this.checkAndEscortMember(state.url);
        }
      })
    );
  }

  private checkAndEscortMember(routerUrl : string): void {
    this.auth.user$.subscribe( (user : any) => {
      if(!user){ //The user object is not yet loaded
        throw 'Did not register a user from Auth0, but expected one';
      }
      
      //This uses a custom OIDC Claim, implemented server side with Auth0
      // https://manage.auth0.com/dashboard/eu/develop-talenttest-ai/actions/library/details/260df027-0ab8-42c8-8bbc-f4607436d937
      // https://auth0.com/docs/get-started/apis/scopes/sample-use-cases-scopes-and-claims
      const membershipActiveUntill = user["talenttest.ai/member"]; // Only set from the server, if membership is active beyond now.
      const hasExpiredMembership = !membershipActiveUntill;
      const checkoutInProgress = routerUrl.startsWith('/join');

      if(hasExpiredMembership && !checkoutInProgress){
          //Go read about the price options
          debugger;
          window.location.assign(environment.landingPage.membership);        
      }

    });
  }
}

const routes: Routes = [
  //Static
  {
    path: 'edit',
    component: AssessmentEditorComponent,
    canActivate: [AuthGuardWithSignup]
  },
  {
    path: 'dashboard',
    component: AssessmentsComponent,
    canActivate: [AuthGuardWithSignup]
  },
  {
    path: 'edit/settings', 
    component: AssessmentSettingsComponent,
    canActivate: [AuthGuardWithSignup]
  },
  {
    path: 'thank-you',
    component: ThankYouComponent
  },
  {
    path: 'join',
    component: JoinComponent,
    canActivate: [AuthGuardWithSignup]
  },
  //Dynamic
  {
    path: 'dashboard/:assessmentId/result/:examId', 
    component: ExamSummaryComponent,
    data: {title: 'Test results'},
    canActivate: [AuthGuardWithSignup]
  },
  {path: '',   redirectTo: '/edit', pathMatch: 'full' },
  {path: '**', component: BrokenLinkComponent},
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }