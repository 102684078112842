<div cdkDropList class="example-list" (cdkDropListDropped)="reordered($event)">
    <div class="example-box" *ngFor="let question of assessment?.questionsForExam" cdkDrag>
        
        <div fxLayout="column" fxLayoutAlign="start center" class="left-action not-for-print">
            <mat-icon class="edit-question" (click)="openEditAnswerDialog(question.problem, question.exampleAnswer!)">
                edit
            </mat-icon>
        </div>

        <div>
            <h3>{{question.problem}}</h3>
        </div>
    </div>
</div>