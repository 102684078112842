import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing/app-routing.module'; 
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';

import { FlexLayoutModule } from '@angular/flex-layout';

import { BusinessAndRoleComponent } from './business-and-role/business-and-role.component';
import { GraphQLModule } from './graphql/graphql.module';
import { AssessmentsComponent } from './assessments/assessments.component';
import { ExamSummaryComponent } from './exam-summary/exam-summary.component';
import { AssessmentEditorComponent } from './assessment-editor/assessment-editor.component';
import { AssessmentSettingsComponent } from './assessment-settings/assessment-settings.component';
import { BrokenLinkComponent } from './broken-link/broken-link.component';
import { AuthRoutingModule } from './auth-routing.module';
import { HomeComponent } from './home/home.component';
import { AddQuestionDialogComponent } from './add-question-dialog/add-question-dialog.component';
import { ActionBarEditorComponent } from './action-bar-editor/action-bar-editor.component';
import { ReorderListComponent } from './reorder-list/reorder-list.component';
import { CopyUrlComponent } from './copy-url/copy-url.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { TimeAgoPipe } from './time-ago.pipe';
import { EditRecommendationDialogComponent } from './edit-recommendation-dialog/edit-recommendation-dialog.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { JoinComponent } from './join/join.component';

@NgModule({
  declarations: [
    AppComponent,
    BusinessAndRoleComponent,
    AssessmentsComponent,
    ExamSummaryComponent,
    AssessmentEditorComponent,
    AssessmentSettingsComponent,
    BrokenLinkComponent,
    HomeComponent,
    AddQuestionDialogComponent,
    ActionBarEditorComponent,
    ReorderListComponent,
    CopyUrlComponent,
    StarRatingComponent,
    TimeAgoPipe,
    EditRecommendationDialogComponent,
    ThankYouComponent,
    JoinComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FlexLayoutModule,
    MatDialogModule,
    GraphQLModule,
    AuthRoutingModule,
    DragDropModule,
    ClipboardModule,
    MatSnackBarModule,
    MatTableModule,
    MatProgressBarModule,
    MatStepperModule,
    MatExpansionModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
