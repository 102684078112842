import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-copy-url',
  templateUrl: './copy-url.component.html',
  styleUrls: ['./copy-url.component.scss']
})
export class CopyUrlComponent implements OnInit {
  @Input()
  url?: string;
  
  constructor(private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  onURLCopied(urlCopiedSuccessfully : boolean) {
    if(urlCopiedSuccessfully){
      this.snackBar.open("URL copied to clipboard", undefined, {duration: 1000});
    }
  }
}
