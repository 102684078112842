import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import pick from 'lodash/pick';
import { switchMap, tap, share, map, debounceTime } from 'rxjs/operators';

import { AddQuestionDialogComponent } from '../add-question-dialog/add-question-dialog.component';
import { BusinessAndRoleComponent } from '../business-and-role/business-and-role.component';
import { Position } from '../intelligence-dto';
import { AssessmentService } from '../assessment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryGenerateCategoriesForArgs, QueryGenerateCurationForArgs, QueryGenerateQuestionsForArgs } from 'src/generated/graphql';
import { AssessmentEditable, SelectableSuggestedQuestion, AUTHOR_INTELLIGENCE, AUTHOR_GUEST  } from '../assessment-editable';
import { combineLatest, forkJoin, merge } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assessment-editor',
  templateUrl: './assessment-editor.component.html',
  styleUrls: ['./assessment-editor.component.scss']
})
export class AssessmentEditorComponent implements AfterViewInit, OnInit {
  
  public assessmentDraft? : AssessmentEditable;
  public isLoadingQuestions = false;
  

  get presentedQuestions(): SelectableSuggestedQuestion[] {
    return this.questionsFrom();
  }

  get questionCategories(): string[] {
    let uniqueCategories = new Set<string>();

    this.assessmentDraft?.questionsCreated.forEach(question => {
      if(question.category){//Do not add the empty OR general category, to a list
        uniqueCategories.add(question.category);
      }
        
    });

    return Array.from(uniqueCategories)
  }

  public questionsFrom(category?: string): SelectableSuggestedQuestion[]{
    if(this.assessmentDraft){
      return this.assessmentDraft.questionsCreated.filter( question => {
          return question.category == category;
      })
    } else {
      return new Array<SelectableSuggestedQuestion>();
    }
  }

  get canRequestQuestions(): boolean {
    return this.assessmentDraft &&
      !!this.assessmentDraft.business &&
      !!this.assessmentDraft.role &&
      !!this.assessmentDraft.focus
      || false ;
  }

  constructor(public materialDialog:MatDialog, private apollo: Apollo, public assessmentService : AssessmentService, 
    private activatedRoute : ActivatedRoute, private router : Router, private element : ElementRef) {
  }

  @ViewChild(BusinessAndRoleComponent) 
  private businessAndRoleComponent!: BusinessAndRoleComponent;

  public loadMoreQuestions = new EventEmitter<void>();
  private storedPosition = new EventEmitter<Position>();

  public clickMoreQuestions(){
    //Show loading bars and bring it into focus
    window.setTimeout(()=>{
      this.element.nativeElement.getElementsByClassName('interview-form')[0].scrollIntoView();
    },0);
    

    //Start loading
    this.loadMoreQuestions.emit();
    //Telemetry
    if (environment.production) {
      // @ts-ignore
      window.lintrk('track', { conversion_id: 11338210 });
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      let fromId = params['assessmentId'];
      let fromSerilization = params['kickstart'];
      if(fromId){
        this.assessmentService.retrieve(fromId).then((result)=>{
          this.assessmentDraft = result.assessment;
          this.storedPosition.emit(pick(this.assessmentDraft, ['role', 'business', 'focus']));
        });
        return;
      }
      if(fromSerilization){
        let result = this.decodeKickstart(fromSerilization);
        this.assessmentDraft = result;
        this.storedPosition.emit(pick(this.assessmentDraft, ['role', 'business', 'focus']));
        return;
      }
    });
  }  

  ngAfterViewInit(): void {
    const newPosition = this.businessAndRoleComponent.newPositionEvent
      .pipe
      (
        debounceTime(1000), //Reinstate debounce to avoid HTTP 429 throttling errors;
        tap( (changedPosition : Position)=>{
          //Load local model
          if(this.assessmentDraft){ //Update current draft
            this.assessmentDraft.business = changedPosition.business;
            this.assessmentDraft.role = changedPosition.role;
            this.assessmentDraft.focus = changedPosition.focus;
          } else {                  //Initialise new draft
            this.assessmentDraft = new AssessmentEditable(
              Date.now().toString(), 
              changedPosition.business, 
              changedPosition.role, 
              new Array<SelectableSuggestedQuestion>(),
              changedPosition.focus
              );
          }
        }),
        share()
    );

    
    combineLatest([this.loadMoreQuestions, merge(this.storedPosition, newPosition)])
    .pipe(
      tap(_ => {
        this.isLoadingQuestions = true;
      }),
      map( ([nothing, position]) => position as Position),
      switchMap( (changedPosition : Position) => { //If there is already a load process underway, then stop it and start a new
        this.assessmentDraft?.removeUnselectedQuestions();
        
        return this.apollo.watchQuery({ //watchQuery can get unsubscribed with switchMap
                  query: SUGGESTION_QUERY,
                  variables: changedPosition,
                  fetchPolicy: 'network-only'
                }).valueChanges
      })
      ).subscribe(({data, loading}) => { //Only subscribe once per component, otherwise the the query is sent multiple times
        this.buildSuggestedQuestionsFormAndCollection(data.generateCurationFor);
        this.isLoadingQuestions = false;

        this.buildCategories();
      });
  }

  //Meant to be called manually to generate landing pages.
  //Go to the console, on a loaded editor, and enode with: 
  // ng.getComponent($('app-assessment-editor')).assessmentDraft.questionsCreated = [ng.getComponent($('app-assessment-editor')).assessmentDraft.questionsCreated.filter(element => element.selected), ng.getComponent($('app-assessment-editor')).assessmentDraft.questionsCreated.filter(element => !element.selected)].flat().slice(0, 40)
  // "https://use.talenttest.ai/edit?kickstart=" + ng.getComponent($('app-assessment-editor')).encodeKickstart()
  public encodeKickstart() : string {
    /*
      https://stackoverflow.com/questions/40244883/cloudfront-responds-with-a-status-of-413-request-entity-too-large
    */
    let questionsCreated = this.assessmentDraft?.questionsCreated.map( item => {
      return pick(item, ['problem', 'author', 'selected']); //Avoid example answers
    });
    let assessmentWithoutId = Object.assign(
      {questionsCreated: questionsCreated},
      pick(this.assessmentDraft, ['role', 'business', 'focus'])
    );
    let serialised = JSON.stringify(assessmentWithoutId)
      // .replaceAll("\"role\":", "\"r\":")
      // .replaceAll("\"business\":", "\"b\":")
      // .replaceAll("\"focus\":", "\"f\":")
      // .replaceAll("\"problem\":", "\"p\":")
      // .replaceAll("\"selected\":\"true\"", "\"s\":\"t\"")
      // .replaceAll("\"selected\":\"false\"", "\"s\":\"f\"")
      // .replaceAll("\"author\":\"intelligence\"", "\"a\":\"i\"")      
      ;

    let encoded = encodeURIComponent(serialised);
    if(encoded.length > 8000){
      throw "Total uri length is approaching the cloudfront limit, reduce the contents."
    }
    return encoded
  }

  decodeKickstart(kickstart : string) : AssessmentEditable {
    //let serialised = decodeURIComponent( (kickstart) )
    // .replaceAll("\"r\":", "\"role\":")
    // .replaceAll("\"b\":", "\"business\":")
    // .replaceAll("\"f\":", "\"focus\":")
    // .replaceAll("\"p\":", "\"problem\":")
    // .replaceAll("\"s\":\"t\"", "\"selected\":\"true\"")
    // .replaceAll("\"s\":\"f\"", "\"selected\":\"false\"")
    // .replaceAll("\"a\":\"i\"", "\"author\":\"intelligence\"")      
    ;
    return AssessmentEditable.fromJSON(JSON.parse(kickstart));
  }

  buildSuggestedQuestionsFormAndCollection(receivedQuestions : string[]){
    let newQuestions = receivedQuestions.map((question) => {
      return new SelectableSuggestedQuestion(question, AUTHOR_INTELLIGENCE, false);
    });

    //Filter out any new questions with duplicate problem statements, by overwriting them in a set
    let allProblems = new Map<String, SelectableSuggestedQuestion>();
    newQuestions.forEach(element => {
      allProblems.set(element.problem, element);
    });
    (this.assessmentDraft!.questionsForExam as SelectableSuggestedQuestion[]).forEach((element) => {
      allProblems.set(element.problem, element); //Updates if there is already an element present.
    })

    this.assessmentDraft!.questionsCreated = Array.from(allProblems.values());
  }
 
  buildCategories() {
    this.apollo.watchQuery({ //TODO should be a single call, not a continuouse watch, or place elsewhere.
      query: CATEGORIES_QUERY,
      variables: {
        role: this.assessmentDraft!.role,
        business: this.assessmentDraft!.business,
        focus: this.assessmentDraft!.focus!
      },
      fetchPolicy: 'network-only'
    }).valueChanges.subscribe(({data, loading})=> {
      for (const category of data.generateCategoriesFor) {
        this.apollo.watchQuery({
          query: DEEP_QUERY,
          variables: {            
            role: this.assessmentDraft!.role,
            business: this.assessmentDraft!.business,
            focus: this.assessmentDraft!.focus!, 
            category: category
          },
          fetchPolicy: 'cache-first'
        }).valueChanges.subscribe(({data, loading}) => {

          let newQuestions = data.generateQuestionsFor.map((question) => {
            return new SelectableSuggestedQuestion(question, AUTHOR_INTELLIGENCE, false, undefined, category);
          });
      
          this.assessmentDraft!.questionsCreated.push(...newQuestions);
        })
      }
    })
    
  }

  openAddQuestionDialog() { // https://v13.material.angular.io/components/dialog/examples#dialog-overview
    const dialogRef = this.materialDialog.open(AddQuestionDialogComponent, {
      maxWidth: '800px',
      minWidth: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){//If the dialogue was closed in the affirmative
        //TODO: Add the username as the author
        this.assessmentDraft!.addQuestion(new SelectableSuggestedQuestion(result, AUTHOR_GUEST, true));   
      }
    });
  }

  nextStep(){
    this.assessmentService.storeDraft(this.assessmentDraft!);
    this.router.navigate(['settings'], {queryParams: {assessmentId: this.assessmentDraft!.id}, relativeTo:this.activatedRoute});
  }
}

//TODO Change naming: generateCurationFor
const SUGGESTION_QUERY = gql<{generateCurationFor: string[];}, QueryGenerateCurationForArgs>`
  query loadNewEditorQuestions($role: String!, $business: String!, $focus: String!) {
    generateCurationFor(role: $role, business: $business, focus: $focus)
  }`;

const CATEGORIES_QUERY = gql<{generateCategoriesFor: string[];}, QueryGenerateCategoriesForArgs>`
query loadNewEditorCategories($role: String!, $business: String!, $focus: String!) {
  generateCategoriesFor(role: $role, business: $business, focus: $focus)
}`;

const DEEP_QUERY = gql<{generateQuestionsFor: string[];}, QueryGenerateQuestionsForArgs>`
query loadNewEditorDeepQuestions($role: String!, $business: String!, $focus: String!, $category: String!) {
  generateQuestionsFor(role: $role, business: $business, focus: $focus, category: $category)
}`;