
<!-- NO Tests-->
<div *ngIf="!descendingAssessments || descendingAssessments.length < 1">
    <h1>Your are ready to start practicing</h1>
    <h2>Create your first test now</h2>
    <button mat-raised-button color="primary" [routerLink]="['../edit']">Start</button>
</div>

<!-- Only visible if there ARE tests -->
<div *ngIf="descendingAssessments && descendingAssessments.length > 0" class="guide">
    <h1>Your practice interviews</h1>
</div>
<ul class="proposed-questions">
    <li *ngFor="let assessment of descendingAssessments">
        <mat-card class="assessment">
            <mat-card-title>
                <div fxLayout="row" style="align-items: center">
                    <div fxFlex="auto">
                        <a class="nostyle">
                            <h2 [routerLink]="['../edit/settings']" [queryParams]="{'assessmentId': assessment.id}">
                            {{assessment.title}}
                            </h2>
                        </a>
                    </div>
                </div>
            </mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
            <mat-card-content>
                <a mat-raised-button color="primary" href="{{assessment.questionaire?.url}}" target="_blank">
                    Begin a practice test
                </a>
                <p>Your results are private, feel free to retake a test as often as you like.</p>
                <p>When you finish a test, come back here to see the result.</p>

                <table *ngIf="assessment.exams?.length" mat-table [dataSource]="assessment.exams!">
                    <!-- https://v13.material.angular.io/components/table/overview -->
                    <!--- Note that these columns can be defined in any order. The actual rendered columns are set as a property on the row definition" -->

                    <!-- Column -->
                    <ng-container matColumnDef="start">
                        <th mat-header-cell *matHeaderCellDef> Start </th>
                        <td mat-cell *matCellDef="let exam"> 
                            <a class="nostyle" [routerLink]="[assessment.id, 'result', exam.id]">{{exam.startDate | date:'short' }}</a>
                        </td>
                    </ng-container>


                    <!-- Column -->
                    <ng-container matColumnDef="score">
                        <th mat-header-cell *matHeaderCellDef> Rating </th>
                        <td mat-cell *matCellDef="let exam">
                            <a class="nostyle" [routerLink]="[assessment.id, 'result', exam.id]">
                                <app-star-rating [rating]="exam.aggregatedRating"></app-star-rating>
                            </a>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="review">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let exam">
                            <a mat-stroked-button [routerLink]="[assessment.id, 'result', exam.id]">Review</a> </td>
                    </ng-container>
                    
                    <!-- Column -->
                    <ng-container matColumnDef="elapsed">
                        <th mat-header-cell *matHeaderCellDef> Elapsed </th>
                        <td mat-cell *matCellDef="let exam">
                            <a class="nostyle" [routerLink]="[assessment.id, 'result', exam.id]">{{minutesBetween(exam.startDate, exam.endDate)}} minutes</a> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsDesktop;"></tr>
                </table>
            </mat-card-content>
            <!-- <mat-card-actions align="end">
              <button mat-button>Like</button>
              <button mat-button>SHARE</button>
            </mat-card-actions> -->
          </mat-card>
    </li>
</ul>    