<form class="interview-form" [formGroup]="businessAndRole" fxLayout="column">
  
  <div fxLayout="column">
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Job title</mat-label>
      <mat-icon matPrefix>work</mat-icon>
      <input matInput formControlName="role" placeholder="For example: Head of Sales"  autocomplete="off">
    </mat-form-field>
    
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Kind of business</mat-label>
      <mat-icon matPrefix>apartment</mat-icon>
      <input matInput formControlName="business"  placeholder="For example: Mobile Apps Studio" autocomplete="off">
    </mat-form-field>
  </div>

  <div fxLayout="column">
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Job description</mat-label>
      <mat-icon matPrefix>description</mat-icon>
      <mat-progress-bar *ngIf="isLoadingFocus" mode="indeterminate"></mat-progress-bar>
      <textarea matInput
        placeholder="Please insert a current job spec, or type in the required competencies for the role"
        cdkTextareaAutosize
        cdkAutosizeMinRows="10"
        cdkAutosizeMaxRows="20"
        formControlName="focus"></textarea>
        <mat-hint align="start" [hidden]="!canEditFocus()">
          <a class="underlined" role="button" (click)="startFocusGeneration.emit()">click here</a> to generate one
        </mat-hint>
    </mat-form-field>
  </div>

</form>